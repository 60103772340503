//deelearn custom - starts
window.addEventListener('DOMContentLoaded', function() {

    // - highlight all pre + code tags (CSS3 selectors)
    // - use javascript as default language
    // - use theme "enlighter" as default theme
    // - replace tabs with 2 spaces
    EnlighterJS.init('pre', 'code', {
        language : 'javascript',
        theme: 'godzilla',
        indent : 2
    });

    //Hide specific EnlighterJS buttons after initialization
    document.querySelectorAll('.enlighter-btn-website').forEach(button => {
        button.style.display = 'none';
    });
  });
//deelearn custom - ends
